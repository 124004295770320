import type { AxiosError } from 'axios';
import { createMutation } from 'react-query-kit';

import { queryClient } from '../common';

import { supabase } from '@/supabase-client';
import { useNotifications } from '@/ui/notifications';

interface UseUploadImageOptions {
  bucket: string;
  fileName: string;
}

type Variables = {
  file: File;
  options: UseUploadImageOptions;
};

type Response = void;

export const useUploadImage = createMutation<Response, Variables, AxiosError>({
  mutationKey: ['upload-image'],
  mutationFn: async ({ file, options: { bucket, fileName } }) => {
    const { error: uploadError } = await supabase.storage
      .from(bucket)
      .upload(fileName, file, {
        upsert: true,
        metadata: { updatedAt: new Date().toISOString() },
      });

    if (uploadError) {
      throw uploadError;
    }
    queryClient.invalidateQueries({ queryKey: ['campaign-image'] });
    queryClient.invalidateQueries({ queryKey: ['organization-image'] });
    queryClient.invalidateQueries({ queryKey: ['user-image'] });
    queryClient.invalidateQueries({ queryKey: ['id-card-image'] });
  },
  onError: () => {
    const { addNotification } = useNotifications();
    addNotification({
      title: 'Upload Failed',
      message: 'Failed to upload organization image',
      type: 'error',
    });
  },
});
